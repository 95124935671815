 //import React from "@types/react";
import React from "react";
import Game1 from "../../Components/Game/Game1";
export const Days = [{
    id: 1, content:
        <>
            <h2>Kacsamell vaníliás-zelleres krumplipürével</h2>
            <p className={'text-brandDark lg:text-base'}>Ha furcsán is hangzik a vanília a pürében, azért próbáld csak ki! Hidd el, semmi desszerthatása nem lesz a dolognak. Éppen csak annyira csavar az ételen, amennyire kell. A többi elemmel kiegészítve pedig egy igazi villantós vacsorát rittyenthetsz viszonylag gyorsan. Pláne, ha a mosogatást nem számoljuk bele.</p>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/lTyuTnADndg"
                        title="VILLANTÓS 🥳 vendégváró! 🦆 Kacsamell vaníliás-zelleres krumplipürével🥔" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/lTyuTnADndg"
                        title="VILLANTÓS 🥳 vendégváró! 🦆 Kacsamell vaníliás-zelleres krumplipürével🥔" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
            </div>
            <p className={'text-brandDark lg:text-sm'}><strong className={"text-brandDark"}>Forrás: </strong><br/>
                <a href={"https://streetkitchen.hu/brutalis-fogasok/kacsamell-vanilias-zelleres-krumplipure/"}
                   target={"blank"}>https://streetkitchen.hu/brutalis-fogasok/kacsamell-vanilias-zelleres-krumplipure/</a>
            </p>
        </>
},
    {
        id: 2, content:
            <>
                <h2>Filmajánló</h2>
                <a href={"https://port.hu/adatlap/film/tv/alkalmi-randevu-holidate/movie-217678"}
                   target={"blank"}>
                    <img src="assets/11nap-filmajanlo-desktop.jpg" alt="Filmajánló" className={"hidden md:block my-4 lg:max-w-[580px]"}/>
                </a>
                <a href={"https://port.hu/adatlap/film/tv/alkalmi-randevu-holidate/movie-217678"}
                   target={"blank"}>
                    <img src="assets/11nap-filmajanlo-mobil.jpg" alt="Filmajánló" className={"md:hidden my-4"}/>
                </a>
            </>
    },
    {
        id: 3, content:
            <>
                <h2>Programajánló</h2>
                <a href={"https://zenehaza.hu/esemeny/hangdimenziok-zenei-utazasok-terben-es-idoben"}
                   target={"blank"}>
                    <img src="assets/3nap-programajanlo-desktop.jpg" alt="Programajánló" className={"hidden md:block my-4 lg:max-w-[580px]"}/>
                </a>
                <a href={"https://zenehaza.hu/esemeny/hangdimenziok-zenei-utazasok-terben-es-idoben"}
                   target={"blank"}>
                    <img src="assets/3nap-programajanlo-mobil.jpg" alt="Programajánló" className={"md:hidden my-4"}/>
                </a>
            </>
    },
    {
        id: 4, content:
            <>
                <h2>Társasjáték-ajánló</h2>
                <a href={"https://tarsasjatekok.com/tarsasjatek/lokomoto-2023"}
                   target={"blank"}>
                    <img src="assets/4nap-tarsasjatek-ajanlo-desktop.jpg" alt="Társasjáték-ajánló" className={"hidden md:block my-4 lg:max-w-[580px]"}/>
                </a>
                <a href={"https://tarsasjatekok.com/tarsasjatek/lokomoto-2023"}
                   target={"blank"}>
                    <img src="assets/4nap-tarsasjatek-ajanlo-mobil.jpg" alt="Társasjáték-ajánló" className={"md:hidden my-4"}/>
                </a>
            </>
    },
    {
        id: 5, content: <>
            <h2>Könyvajánló</h2>
            <a href={"https://www.lira.hu/hu/konyv/ismeretterjeszto-1/szakacskonyvek/a-hivatalos-harry-potter-szakacskonyv"}
               target={"blank"}>
                <img src="assets/5nap-konyvajanlo-desktop.jpg" alt="Köny ajánló" className={"hidden md:block my-4 lg:max-w-[580px]"}/>
            </a>
            <a href={"https://www.lira.hu/hu/konyv/ismeretterjeszto-1/szakacskonyvek/a-hivatalos-harry-potter-szakacskonyv"}
               target={"blank"}>
                <img src="assets/5nap-konyvajanlo-mobil.jpg" alt="Könyv ajánló" className={"md:hidden my-4"}/>
            </a>
        </>
    },
    {
        id: 6, content: <>
            <h2>Alfa Alkotók DIY karácsonyi ajándék ötletei</h2>
            <p className={"text-brandDark"}>
                Még van idő saját kezűleg elkészíteni a karácsonyi ajándékokat. Nézzétek meg a kisfilmet és inspirálódjatok a kollégák ötleteiből.
            </p>
            <div className={'hidden 2xl:flex justify-center my-4'}>
                <video width="560" controls>
                    <source src="assets/6nap-Alfa_DIY_Dobo_Zsanett_video_kis_meret.mp4"/>
                </video>
            </div>
            <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                <video width="500" controls>
                    <source src="assets/6nap-Alfa_DIY_Dobo_Zsanett_video_kis_meret.mp4"/>
                </video>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <video width="100%" height="250" controls>
                    <source src="assets/6nap-Alfa_DIY_Dobo_Zsanett_video_kis_meret.mp4"/>
                </video>
            </div>
        </>
    },
    {
        id: 7, content: <>
            <h2>Elő a korcsolyákkal! Mutatjuk a legjobb jégpályákat az országban</h2>
            <p>
                <strong className={"text-brandDark"}>A téli sportok szerelmeseinek van egy jó hírünk, ugyanis cikkünkben
                    most összegyűjtöttük az ország legjobb budapesti és vidéki jégpályáit. Ideje tehát előkapni a
                    korcsolyát!</strong>
            </p>
            <p className={"text-brandDark"}>
                A korcsolyázás ugyanis amellett, hogy egy igazán hangulatos téli program, még abban is segít, hogy az
                ünnepek előtt kicsit formába hozzuk magunkat és ne a pluszkilók elleni küzdelemmel induljon majd az új
                év. Jégpályát pedig az ország szinte minden táján találunk, így még az sem lehet kifogás, hogy utaznunk
                kell, hogy hódolhassunk szenvedélyünknek. Mutatjuk a kedvenc vidéki és budapesti helyszíneinket!
            </p>
            <h3>Városligeti Műjégpálya</h3>
            <p className={"text-brandDark"}>
                Európa egyik legnagyobb szabadtéri mesterséges jégfelületeként a kontinens legjobb ilyen helyszíneit
                rangsoroló, nemzetközi listákon is mindig előkelő helyen szerepel a Városligeti Műjégpálya. Ennek a
                helynek a történelme pedig egészen 1869-ig nyúlik vissza, hiszen a Pesti Korcsolyázó Egylet ekkor kapott
                először városi tanácsi engedélyt a Városligeti tó korcsolyapályaként való használatára. Persze az sem
                utolsó szempont, hogy a jégen keringve a történelmi jelentőségű Vajdahunyad vára szolgáltatja a
                hátteret.
            </p>
            <img src="assets/7nap-varosligeti-mujegpalya.jpg" alt="Városligeti Műjégpálya"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Nyitvatartás:</strong> hétfő-péntek 9:00-13:00 és 17:00-21:00;
                szombat: 10:00-14:00 és 16:00-21:00; vasárnap: 10:00-14:00 és 16:00-20:00<br/>
                <strong className={"text-brandDark"}>Jegyárak:</strong> A Városligeti Műjégpálya jegyárai 2000-4000
                forint között alakulnak, de családi belépőt is vásárolhattok. A pontos árak <a
                href={"https://jegy.mujegpalya.hu/"} target={"_blank"} className={"text-brandTertiary"}>IDE
                KATTINTVA</a> láthatóak.
            </p>
            <h3>Csepeli Jégpark</h3>
            <p className={"text-brandDark"}>
                AA több mint 2000 m²-es fantasztikus panorámájú pálya egy 800 m²-es, egy kisebb 300 m²-es és egy 200
                m²-es tanuló pályából áll, amiket 250 méternyi jégfolyosó köt össze.
            </p>
            <img src="assets/7nap-csepeli-jegpark.jpg" alt="Csepeli jégpark"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Nyitvatartás:</strong> November 22.től február 28.-ig,
                minden nap 08:00 - 21:00<br/>
                <strong className={"text-brandDark"}>Jegyárak:</strong> A belépő 2500 Ft 3 éves kor felett. Csepelieknek
                1500 Forint, lakcímkártya felmutatásával. (Bővebb információ <a href={"https://www.csepelijegpark.com/"}
                                                                                target={"_blank"}
                                                                                className={"text-brandTertiary"}>IDE
                KATTINTVA</a>)
            </p>
            <h3>Székesfehérvár, Kori liget</h3>
            <p className={"text-brandDark"}>
                Igazi, mesébe illő korcsolyapálya a Székesfehérvár belvárosának északi részén, a Zichy-ligetben
                kialakított kori pálya. A fákkal körülölelt történelmi parkban az adventi időszakban fényárban úszik a
                jégpálya, mely teljes egészében körbeveszi a romantikus hangulatú öntöttvas zenepavilont. A
                székesfehérvári Kori ligetben értékmegőrző, büfé és korcsolyabérlési lehetőség is van.
            </p>
            <img src="assets/7nap-kori-liget.jpg" alt="Kori liget"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Nyitvatartás:</strong> Hétköznap 8:00-tól 20:00-ig, hétvégén és
                téli szünetben 10:00-tól 20:00-ig<br/>
                <strong className={"text-brandDark"}>Jegyárak:</strong> 1000 Forint és 2500 Forint mozognak (Bővebb
                információ <a href={"https://www.facebook.com/koriliget/"} target={"_blank"}
                              className={"text-brandTertiary"}>IDE KATTINTVA</a>)
            </p>
            <h3>Különleges jégpálya Balatonfüreden</h3>
            <p className={"text-brandDark"}>
                Balatonfüred nyáron kétségkívül az ország egyik legszebb helye, viszont a város télen is igyekszik
                megőrizni ezt az imidzset. A füredi Zákonyi jégpályát idén pedig egy jégfolyosóval és két helyszínnel
                bővítették ki, így a Tagore sétány és a Vitorlás tér is elérhetővé válik korcsolyával közlekedve. Így a
                több mint 1600 négyzetméter összterületű pálya különböző téli sportokra is alkalmassá vált, jéghoki
                hálók, jégteke szett, korcsolyázást elősegítő állatfigurák használhatók. A belépésre két helyen
                biztosítanak lehetőséget, a Zákonyi-téri és a Vitorlás-téri pályánál is megválthatjuk jegyeinket.
            </p>
            <img src="assets/7nap-balatonfured-jegpalya.jpg" alt="Balatonfüred jégpálya"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Nyitvatartás:</strong> 2024. december 6. – 2024. február 2. (Bővebb
                információ <a
                href={"https://www.facebook.com/furedijegpalya/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"}
                target={"_blank"} className={"text-brandTertiary"}>IDE KATTINTVA</a>)<br/>
                <strong className={"text-brandDark"}>Jegyárak:</strong> Felnőtt: 2200 Forint; Diák: 1500 Ft; 3-6 év
                közötti gyerekjegy: 1000 Forint; a balatonfüredi lakosok pedig kedvezményes jegyeket válthatnak
            </p>
            <h3>Pécs Városi Műjégpálya</h3>
            <p className={"text-brandDark"}>
                A Pécs Városi Műjégpálya november 9-e óta várja a korizni vágyókat. A Dr. Veress Endre utcában található
                szabadtéri jégpálya a szabadidős korcsolya mellett kezdő és haladó korcsolya tanfolyamokat is indít,
                amelyre a helyszínen lehet jelentkezni. A Pécs Városi Műjégpályán korcsolyabérlésre, sőt, élezésre is
                lehetőség van, s bár melegedő nincsen, a pálya melletti lelátón bőséges hely van a pihenésre,
                üldögélésre.
            </p>
            <img src="assets/7nap-pecsi-mujegpalya.jpg" alt="Pécsi városi műjégpálya"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Nyitvatartás:</strong> hétfőtől csütörtökig: 9:00-12:00,
                13:00-16:00; péntektől vasárnapig: 9:00-12:00, 13:00-16:00, 17:00-20:00<br/>
                <strong className={"text-brandDark"}>Jegyárak:</strong> 1000 Ft és 1800 Ft között mozognak (Bővebb
                információ <a href={"https://www.psnzrt.hu/mujegpalya/"} target={"_blank"}
                              className={"text-brandTertiary"}>IDE KATTINTVA</a>)
            </p>
            <p className={'text-brandDark lg:text-sm lg:mt-8'}><strong className={"text-brandDark"}>Forrás: </strong><br/>
                <a
                    href={"https://woohoo.hu/elo-a-korcsolyakkal-mutatjuk-a-legjobb-jegpalyakat-az-orszagban/"}
                    target={"blank"}>https://woohoo.hu/elo-a-korcsolyakkal-mutatjuk-a-legjobb-jegpalyakat-az-orszagban/</a><br/>
                <a
                    href={"https://www.travelo.hu/rendezveny/20241119-korcsolyapalya-kori-palya-mujegpalya-korcsolya-jegpalya-jeg-jegkori-palya.html"}
                    target={"blank"}>https://www.travelo.hu/rendezveny/20241119-korcsolyapalya-kori-palya-mujegpalya-korcsolya-jegpalya-jeg-jegkori-palya.html</a>
            </p>
        </>
    },
    {
        id: 8, content: <>
            <h2>Amerikai mázas fahéjas csiga</h2>
            <p className={'text-brandDark lg:text-base'}>A téli időszakkal együtt közeleg a baráti összejövetelek ideje
                is. Ezekre pedig érdemes a legjobb falatokkal készülni! Én tuti ezt az amerikai mázas fahéjas csigát
                készítem el idén, hogy mindenkinek igazán édesek legyenek az ünnepek.
            </p>
            <h3>Recept</h3>
            <img src="assets/8nap-fahejas-csiga.jpg" alt="Fahéjas csiga"/>
            <h4 className={"text-brandDark my-4"}>Hozzávalók - 10 darabhoz</h4>
            <p><strong className={"text-brandDark"}>A tésztához:</strong></p>
            <div className={"block md:flex"}>
                <p className={"text-brandDark w-80 mb-0 md:mb-4"}>
                    &bull; 300 ml 3,5%-os tej<br/>
                    &bull; 1 csomag porélesztő (7 g-os kiszerelés)<br/>
                    &bull; 50 g kristálycukor<br/>
                    &bull; 600 g finomliszt + a nyújtáshoz<br/>
                </p>
                <p className={"text-brandDark"}>
                    &bull; 1 csipet só<br/>
                    &bull; 2 db tojás<br/>
                    &bull; 1 tk vaníliaőrlemény<br/>
                    &bull; 115 g puha vaj<br/>
                </p>
            </div>
            <p><strong className={"text-brandDark"}>A karamellás krémhez:</strong></p>
            <div className={"block md:flex"}>
                <p className={"text-brandDark w-80 mb-0 md:mb-4"}>
                    &bull; 115 g vaj<br/>
                    &bull; 220 g barna cukor<br/>
                    &bull; 2 tk őrölt fahéj<br/>
                </p>
                <p className={"text-brandDark"}>
                    &bull; 115 g méz<br/>
                    &bull; 1 csipet só<br/>
                    &bull; 45 ml tejszín<br/>
                </p>
            </div>
            <p><strong className={"text-brandDark"}>A fahéjas töltelékhez:</strong></p>
            <div className={"block md:flex"}>
                <p className={"text-brandDark w-80 mb-0 md:mb-4"}>
                    &bull; 115 g puha vaj<br/>
                    &bull; 220 g barna cukor<br/>
                </p>
                <p className={"text-brandDark"}>
                    &bull; 2 ek őrölt fahéj<br/>
                    &bull; 1 csipet só<br/>
                </p>
            </div>
            <p><strong className={"text-brandDark"}>A tetejére:</strong></p>
            <div className={"block md:flex"}>
                <p className={"text-brandDark w-80 mb-0 md:mb-4"}>
                    &bull; 170 g krémsajt<br/>
                    &bull; 85 g puha vajr<br/>
                </p>
                <p className={"text-brandDark"}>
                    &bull; 100 g porcukor<br/>
                    &bull; 1 tk vaníliaőrlemény<br/>
                </p>
            </div>
            <p className={"text-brandDark"}>Elkészítési idő: 40 perc | Pihentetési idő: 1 óra 45 perc | Sütési idő: 30-35 perc</p>
            <h4 className={"text-brandDark mb-4"}>Elkészítés:</h4>
            <p className={"text-brandDark"}>
                Az amerikai mázas fahéjas csiga elkészítéséhez egy dagasztógép üstjébe tesszük a tejet, az élesztőt, a cukrot, a lisztet, sót, a tojásokat és a vaníliaőrleményt, és a géppel elkezdjük összedolgozni. Amikor kezd összeállni a tészta, hozzáadagoljuk a felkockázott vajat kis részletekben. 4-5 percig dagasztjuk a tésztát, majd légmentesen lezárva 60 percig kelesztjük.<br/>
                A karamellás krémhez a vajat, a cukrot, a fahéjat, a mézet és sót egy tálban robotgéppel keverni kezdjük, közben a tejszínt lassan hozzácsorgatjuk. Egy 20 x 30 cm-es tepsi aljára kenjük a karamellás krémet úgy, hogy egyenletesen fedje mindenhol. Ezután szintén robotgéppel kikeverjük a fahéjas töltelék hozzávalóit is egy tálban.<br/>
                A megkelt tésztát enyhén lisztezett felületen 1 cm-es vastagságúra nyújtjuk, egyenletesen elkenjük rajta a fahéjas tölteléket, majd levágjuk a tészta széleit, hogy egyenes oldalú téglalapot kapjunk. A hosszabbik oldalnál kezdve szorosan feltekerjük, majd 10 egyenlő csigát vágunk belőle. Ezeket a csigákat belepakoljuk a tepsibe, majd szobahőmérsékleten további 30 percig kelesztjük. Ha megkeltek a csigák, 180 fokra előmelegített sütőben 30-35 perc alatt készre sütjük, majd 10-15 percig hűlni hagyjuk őket.<br/>
                A tetejére kerülő krémsajtos máz hozzávalóit alaposan kikeverjük, lekenjük vele a csigákat és még melegében tálaljuk.<br/>
            </p>
            <p className={'text-brandDark lg:text-sm lg:mt-8'}><strong className={"text-brandDark"}>Forrás: </strong><br/>
                <a
                    href={"https://streetkitchen.hu/fantasztikus-desszertek/amerikai-mazas-fahejas-csiga/"}
                    target={"blank"}>https://streetkitchen.hu/fantasztikus-desszertek/amerikai-mazas-fahejas-csiga/</a>
            </p>
        </>
    },
    {
        id: 9, content:
            <>
                <h2>Alfa Alkotók DIY karácsonyi ajándék ötletei</h2>
                <p className={"text-brandDark"}>
                    Még van idő saját kezűleg elkészíteni a karácsonyi ajándékokat. Nézzétek meg a kisfilmet és
                    inspirálódjatok a kollégák ötleteiből.
                </p>
                <div className={'hidden 2xl:flex justify-center my-4'}>
                    <video width="560" controls>
                        <source src="assets/9nap-Alfa_DIY_Drevenka_Edit_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                    <video width="500" controls>
                        <source src="assets/9nap-Alfa_DIY_Drevenka_Edit_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/9nap-Alfa_DIY_Drevenka_Edit_video_kis_meret.mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 10, content: <>
            <h2>Csináld magad: 30 Karácsonyi Dekoráció 5 Perc Alatt</h2>
            <p className={'text-brandDark lg:text-base'}>Fedezd fel, hogyan varázsolhatsz ünnepi hangulatot otthonodba csupán 5 perc alatt! Ezek az egyszerű, mégis látványos karácsonyi dekorációs ötletek garantáltan inspirálnak az alkotásra.</p>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/EMZl0XwTttE"
                        title="30 Amazing Christmas Decorations You Can Make In 5 Minutes" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/EMZl0XwTttE"
                        title="30 Amazing Christmas Decorations You Can Make In 5 Minutes" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
            </div>
        </>
    },
    {
        id: 11,
        content: <>
            <h2>Alfa Alkotók DIY karácsonyi ajándék ötletei</h2>
            <p className={"text-brandDark"}>
                Még van idő saját kezűleg elkészíteni a karácsonyi ajándékokat. Nézzétek meg a kisfilmet és
                inspirálódjatok a kollégák ötleteiből.
            </p>
            <div className={'hidden 2xl:flex justify-center my-4'}>
                <video width="560" controls>
                    <source src="assets/11nap-Alfa_DIY_Borsodi_Imre_video_kis_meret.mp4"/>
                </video>
            </div>
            <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                <video width="500" controls>
                    <source src="assets/11nap-Alfa_DIY_Borsodi_Imre_video_kis_meret.mp4"/>
                </video>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <video width="100%" height="250" controls>
                    <source src="assets/11nap-Alfa_DIY_Borsodi_Imre_video_kis_meret.mp4"/>
                </video>
            </div>
        </>
    },
    {
        id: 12,
        content: <>
            <h2>TOP 4 adventi vásár Budapesten</h2>
            <p>
                <strong className={"text-brandDark"}>Budapest 2024-ben is fantasztikus adventi programokkal vár! Ebben a
                    listában felfedezheted a legszebb karácsonyi vásárokat, a garantáltan szuper jégpályákat és a
                    legjobb ünnepi koncerteket.</strong>
            </p>
            <h3>Bazilika Advent és Karácsonyi Vásár</h3>
            <p className={"text-brandDark"}>
                Évről évre emberek ezrei özönlenek a Szent István térre, hogy sétát tegyenek a karácsonyi vásár
                díszfényes standjai között. Tavaly is szívderítő élmény volt ellátogatni a Bazilikához, hiszen a
                hagyományos kézműves és gasztronómiai árusok mellett termérdek ünnepi program is várta a vendégeket. És
                persze nem marad el a közös gyertyagyújtás sem.<br/>
                <br/>
                Az Adventi Ünnep a Bazilikánál tavaly 3. alkalommal nyerte el az Európa legszebb adventi vásárának járó
                díjat.<br/>
                12 méter magas karácsonyfa, betlehemi jászol, hatalmas adventi koszorú – ezek mind-mind hozzájárulnak
                ahhoz, hogy a várakozás időszaka a Bazilikánál igazán különleges legyen. Idén is lesznek kézműves
                kiállítók: keramikusok, bőrdíszművesek, ötvösök, de a látogatók találkozhatnak új kiállítókkal és
                termékekkel.<br/>
                Nem maradhatnak el ugyanakkor a gasztronómiai élmények sem. A téli ízek mellett megtalálhatóak lesznek
                hungarikumok és igazi kulináris izgalmak is a gasztronómia kalandorainak.<br/>
                Minden délután 17:30-tól a bazilika homlokzatán csodálatos fényfestés kápráztatja majd el a közönséget,
                amely előtt bizonyos napokon egy színvonalas, zenei koncertet láthatnak a térre érkezők.<br/>
            </p>
            <img src="assets/12nap-bazilika-vasar.jpg" alt="Bazilika vásár"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Időpont:</strong> 2024. november 15. - 2025. január 1.<br/>
                <strong className={"text-brandDark"}>Helyszín:</strong> Szent István tér
            </p>
            <h3>Vörösmarty Advent és Karácsonyi Vásár</h3>
            <p className={"text-brandDark"}>
                A hagyományokhoz híven az adventi időszak alatt a legjobb hazai kézművesek érkeznek a Vörösmarty térre,
                hogy egyedi portékáikkal elkápráztassák a vásárlókat. A budapesti karácsonyi vásár kiállítóit egy
                független szakmai zsűri ellenőrzi, hogy a lehető legmagasabb minőséget tudják biztosítani a
                vásározóknak.<br/>
                <br/>
                A Budapesti Adventi és Karácsonyi Vásár minden szegletében a forralt borok fűszeres zamata és a kis
                manufaktúrák ínycsiklandó, házi készítésű ételei keresztezik utunkat. A gasztrokiállítók egytől-egyig a
                hazai élvonalba tartoznak, így kiemelkedően magas színvonalra számíthatunk harapnivalók terén.
            </p>
            <img src="assets/12nap-vorosmarty-vasar.jpg" alt="Vörösmarty vásár"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Időpont:</strong> 2024. november 15. - december 31.<br/>
                <strong className={"text-brandDark"}>Helyszín:</strong> Vörösmarty tér
            </p>
            <h3>Újbudai Advent és Karácsonyi Vásár</h3>
            <p className={"text-brandDark"}>
                Az Újbudai Karácsonyi Vásár 2024. december 1-24. között lopja be az ünnep varázsát az Allee pláza
                melletti Kőrösy József sétányra. Garantált a felhőtlen kikapcsolódás, hiszen minden évben
                díszkivilágítással, kézműves ajándékokkal és illatozó téli finomságokkal csábítják az érdeklődőket.
            </p>
            <img src="assets/12nap-ujbudai-vasar.jpg" alt="Újbudai vásár"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Időpont:</strong> 2024. december 6-24.<br/>
                <strong className={"text-brandDark"}>Helyszín:</strong> 11. Etele-tér
            </p>
            <h3>Hegyvidék Karácsonyi Vásár</h3>
            <p className={"text-brandDark"}>
                Engedj a gőzölgő forraltbor, az aranybarna kalácsok és a fahéjas sütemények csábításának vagy vesd bele
                magad a kézműves vásár kincstengerébe!
            </p>
            <img src="assets/12nap-hegyvidek-vasar.jpg" alt="Hegyvidék vásár"/>
            <p className={"mt-5 text-brandDark"}>
                <strong className={"text-brandDark"}>Időpont:</strong> 2024. december 1-23.<br/>
                <strong className={"text-brandDark"}>Helyszín:</strong> 12. kerület, Városház tér
            </p>
            <p className={'text-brandDark lg:text-sm lg:mt-8'}><strong
                className={"text-brandDark"}>Forrás: </strong><br/>
                <a
                    href={"https://szallas.hu/programok/karacsonyi-vasarok-budapesten-2024-budapest-p21896?_gl=1*ivjsox*_up*MQ..*_ga*bm8tZGF0YQ..*_ga_8QFX7JXGKS*MTczMTU4NjY2Mi4xLjAuMTczMTU4NjY2Mi4wLjAuMA.."}
                    target={"blank"}>https://szallas.hu/programok/karacsonyi-vasarok-budapesten-2024-budapest/</a>
            </p>
        </>
    },
    {
        id: 13, content:
            <>
                <h2>Alfa Alkotók DIY karácsonyi ajándék ötletei</h2>
                <p className={"text-brandDark"}>
                    Még van idő saját kezűleg elkészíteni a karácsonyi ajándékokat. Nézzétek meg a kisfilmet és
                    inspirálódjatok a kollégák ötleteiből.
                </p>
                <div className={'hidden 2xl:flex justify-center my-4'}>
                    <video width="560" controls>
                        <source src="assets/13nap-Alfa_DIY_Jaszai_Eniko_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                    <video width="500" controls>
                        <source src="assets/13nap-Alfa_DIY_Jaszai_Eniko_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/13nap-Alfa_DIY_Jaszai_Eniko_video_kis_meret.mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 14, content:
            <>
                <h2>Macskarácsony</h2>
                <img src="assets/14nap-cica.gif" alt="Cica gif"
                     className={"my-4 lg:max-w-[580px]"}/>
            </>
    },
    {
        id: 15, content:
            <Game1/>
    },
    {
        id: 16, content:
            <>
                <h2>Alfa Alkotók DIY karácsonyi ajándék ötletei</h2>
                <p className={"text-brandDark"}>
                    Még van idő saját kezűleg elkészíteni a karácsonyi ajándékokat. Nézzétek meg a kisfilmet és
                    inspirálódjatok a kollégák ötleteiből.
                </p>
                <div className={'hidden 2xl:flex justify-center my-4'}>
                    <video width="560" controls>
                        <source src="assets/16nap-Alfa_DIY_Szalai_Zita_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                    <video width="500" controls>
                        <source src="assets/16nap-Alfa_DIY_Szalai_Zita_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/16nap-Alfa_DIY_Szalai_Zita_video_kis_meret.mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 17, content:
            <>
                <h2>A konyhában is felkészülten</h2>
                <p className={"text-brandDark"}>
                    Emlékeztek tavalyi videosorozatunkra, amelyben vállalatunk vezetői készítették el a legnépszerűbb ünnepi fogásokat? Elevenítsük fel a legjobb pillanatokat, és inspirálódjunk együtt a tuti receptekből!
                </p>
                <div className={'hidden 2xl:flex justify-center my-4'}>
                    <video width="560" controls>
                        <source src="assets/17nap-Alfa_adventi_fozes_mood_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                    <video width="500" controls>
                        <source src="assets/17nap-Alfa_adventi_fozes_mood_video_kis_meret.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/17nap-Alfa_adventi_fozes_mood_video_kis_meret.mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 18, content:
            <>
                <h2>Kedves Kollégák!</h2>
                <p className={'text-brandDark lg:text-base'}>Köszönöm mindenkinek az idei év közös sikereit!<br/>
                    Kívánok nektek és családjaitoknak békés, meghitt ünnepeket, sok örömöt és boldogságot! Pihenjetek
                    sokat, töltődjetek fel, és térjünk vissza az új évben új lendülettel.<br/>
                    <br/>
                    Áldott, békés karácsonyt és boldog új évet!<br/>
                    <br/>
                    <i className={'text-brandDark lg:text-base'}>Zatykó Péter</i>
                </p>
                <img src="assets/18nap-hopihe.png" alt="Hopihe" className={"my-4 lg:max-w-[580px] md:mx-auto"}/>
            </>
    },
    {
        id: 19, content:
            <>
                <h2>Könyvajánló</h2>
                <p className={'text-brandDark lg:text-base'}>
                    Az alábbi szubjektív lista nem a mindenki által ismert, népszerű írók könyvei közül válogat – inkább
                    a titkos gyöngyszemek közül. Azoknak is ajánljuk, akik sokat olvasnak, és azoknak is, akik most
                    akarnak visszaszokni a regény olvasásra.
                </p>
                <h3>Alan Bennett: A királynő olvas</h3>
                <img src="assets/19nap-konyv-1.jpg" alt="Könyv 1"
                     className={"block mb-2 mx-auto sm:float-left sm:mr-6 max-w-[150px]"}/>
                <p className={'text-brandDark lg:text-base'}>
                    Vékonyka, tréfás és mély könyv, amely II. Erzsébetről és az olvasásról szól. Egy nyolcvan éves
                    asszony fejlődésregénye. Egy életszerű jelenettel indul: a királynő engedetlen kutyáit hajkurászva a
                    palota egy félreeső zugában egy könyvtárbuszba ütközik, ahonnét udvariasságból kikölcsönöz egy
                    könyvet. Ez aztán lassú, de egyre gyorsuló eróziót indít meg az életében. Az udvar azonban nem
                    akarja hagyni. A körülötte szolgálatot teljesítő emberek kisebb-nagyobb trükközéssel vissza akarják
                    terelni a régebbi, kötelességtudó, nem olvasó szerepbe. A könyv vagy egy tucat nagyszerűen
                    jellemzett figurát vonultat fel, közülük a legérdekesebb az előnytelen külsejű konyhai
                    segédmunkásfiú, akivel az első jelenetben találkozik a főhős a könyvtárbuszon, akit a királynő
                    „kiemel”, és egyre nagyobb szerepet ad neki saját életében. A selyempuhaságú stílus, a finom humor a
                    nagy angol elbeszélők legjobb napjait idézi. A cselekmény végig izgalmas, a fordulatok teljességgel
                    megjósolhatatlanok. És miközben olvasunk, mintegy négyzetre emelődik az élvezet, amikor az olvasás
                    kéjéről elmélkedik a ma 90 éves szerző.<br/>
                    A királynő, ahogy egy igazi fejlődésregényben illik, sokat változik: szánni való konvencionális
                    matrónából gondolkodó, érző lénnyé, aki már nem elégszik meg az olvasással, hanem egyenesen könyvet
                    akar írni… De ez már a végkifejlet csattanójához tartozik…<br/>
                    (The Uncommon Reader, Magvető, 2024, fordította Rakovszky Zsuzsa, 152 o., ára 4499 Ft.)
                </p>
                <h3>Orhan Pamuk: A pestis éjszakái</h3>
                <img src="assets/19nap-konyv-2.jpg" alt="Könyv 2"
                     className={"block mb-2 mx-auto sm:float-right sm:ml-6 max-w-[150px]"}/>
                <p className={'text-brandDark lg:text-base'}>
                    Ennek a zömében 1901-ben játszódó nagyregénynek több főszereplője is van. Elsősorban Minger, a
                    néhány tízezer fő lakta, vegyes, török-görög lakosságú sziget, Krétától kissé észak-keletre. A
                    legelső lapokon egy részletes térkép mutatja be a sziget elhelyezkedését, a fővárost, és a kikötői
                    negyedet, ahol a többi főszereplő él. Ők Sami pasa, a sziget kormányzója, Nuri doktor (a pestis
                    miatt ideküldött járványügyi szakember) és fiatal felesége, Pakize szultána (aki levelek százaiban
                    számol be Hatice nővérének a szigeten történtekről), az ő testőrük, az idevalósi Kâmil őrnagy és
                    mások. A különlegesen egyénített szereplők annyira élnek, hogy szinte leugranak a regény
                    lapjairól.<br/>
                    A cselekmény nemcsak megdöbbentő fordulatokban gazdag, de tartalmaz egy vérbeli krimiszálat is. (Ezt
                    az író egyáltalán nem blicceli el, bár egy ideig halogatja a gyilkos kilétének felfedését.)<br/>
                    Sok száz oldal után esik le a tantusz: ez a regény többek között az emberi társadalom
                    kormányozhatóságáról szól. Ami az 1901. év Mingerén igencsak nehezített pálya volt: a lassan, de
                    biztosan széthulló Oszmán Birodalomban, ahol a Tanzimat reformkorszak legtöbb újítása megfeneklett.
                    Hiszen már amikor tombol a járvány, akkor se lehet a karantént tisztességesen betartatni, pedig a
                    hatalom egyre folytatja vándorútját, és egyre meglepőbb kezekbe kerül… Mígnem egy ponton… De innét
                    már nem lehet tovább mesélni a kacskaringós történetet. Legyen elég annyi, hogy egészen a 657.
                    oldalig tartanak a fordulatok ebben a magával ragadó és mindvégig letehetetlen regényben.<br/>
                    Olvasás közben mindenkit izgatott, hogy hol húzódik a könyvben a valóság és a fikció kanyargós
                    határvonala. Nos, az Oszmán Birodalom minden kétségen felül létezett, sőt 1901-ban valóban volt
                    pestisjárvány… Létezett továbbá Hatice szultána, akinek a levelek szóltak… Sőt, a regény vége felé
                    kétszer is emlegetett Orhan Pamuk nevű Nobel díjas regényíró ma is él és jó egészségnek örvend.<br/>
                    (Helikon Kiadó, 2023. Fordította Nemes Péter. 567 o., ára: 5999 Ft.)
                </p>
                <h3>Jolsvai András: Édesszájúak</h3>
                <img src="assets/19nap-konyv-3.jpg" alt="Könyv 3"
                     className={"block mb-2 mx-auto sm:float-left sm:mr-6 max-w-[150px]"}/>
                <p className={'text-brandDark lg:text-base'}>
                    <i className={'text-brandDark lg:text-base'}>„Ez egy napsütötte regény. Hársfaillatú, eperízű, szerelmetes. Egy szerethető család története. Egy házaspáré és a gyermekeiké, egy vendéglőé, és egy olyan koré, amelyben olykor édes is lehetett az élet. Ebben a családban biztosan. <br/>
                    Operett, regényben elbeszélve, mondja művéről a szerző, ami persze túlzás, hiszen a történetnek vannak árnyékos lapjai is, de a végén azért jóra fordulnak a dolgok. (Szinte.)”</i><br/>
                    Ez a szöveg szerepel a fülön, és a lelkesedés indokolt.<br/>
                    Jolsvai András egyszer egy valaki a magyar Erich Kästnernek nevezte.hez hasonlítottam. Az Édesszájúakban kevesebb a német író, több a Jolsvai, abban az értelemben, hogy ez valahogy tárgyiasabb. Olyan, mintha egy vendéglátás-történész egy eredményes élet után nyugdíjasként – sikerrel – regényírásba fogna. Mert az egyik főszereplő, a Gizella út és a Stefánia közötti hatalmas telken működő Zimmerli étterem, ahol minden üst és fakanál, minden munkafázis a helyén van. De a mellékszereplők mind létező személyek, Benczúr Gyulától Heltai Jenőig.<br/>
                    Az Édesszájúak elsősorban családregény, egy házaspár és négy gyermekének portréja, az író pedig az 1893 és ’95 közötti életüket meséli el. Csodálatosan egyénítettek a férjhez menés lázában égő lányok (egyáltalán nem hasonlítanak egymásra), de az igazi mestermunka az egyetlen fiú, Franzl portréja. Annál is inkább, mert ez a cselekményvonal ellenpontozza a nagy sikertörténetet. Apja már a gyerek négyéves korában ráébred arra, hogy egyetlen fia bizony nem fogja tovább vinni a vendéglőt, egy élet munkáját.<br/>
                    Franzl egyénítése nem más, mint az enyhe fokú autizmus csodálatos retro-ábrázolása, egy olyan korban, amikor e tünetegyüttesnek még nem volt neve. Egy tucatnyi felejthetetlen epizódon keresztül. Ahogy megtanul biciklizni. Ahogy szét- és összeszereli a házhoz frissen került varrógépet. Ahogy tizennyolcadik születésnapja estéjén viselkedik, amikor apja elviszi őt a szép lányokhoz. (Nem, ő nem meleg, bár jó ideig minden olvasó azt hiheti, hogy ez lesz a csattanója a történetnek.)<br/>
                    Szeretettel, mesterien megírt, szórakoztató történet a boldog békeidőkről, amikor az ország egyértelműen felfelé ívelő pályán volt.<br/>
                    Szép emlékműve azoknak a nagyszerű asszonyoknak is, akik a modern Magyarországot a két kezükkel felépítették, szerényen megbújva uruk háta mögött. Akik reggel négykor keltek, éjfél előtt ágyba ritkán kerültek.<br/>
                    Az utószóból kiderül, hogy a történet minden egyes szereplője – megengedve, hogy itt-ott talán kiegészítették az embergalériát – az író felmenője volt.<br/>
                    (Kalligram, 2024, a borítót Szabadi Gergely tervezte, 255 o., 4500 Ft.)

                </p>
                <h3>Guillaume Musso: Regényélet</h3>
                <img src="assets/19nap-konyv-4.jpg" alt="Könyv 4"
                     className={"block mb-2 mx-auto sm:float-right sm:ml-6 max-w-[150px]"}/>
                <p className={'text-brandDark lg:text-base'}>
                    Musso, az ötvenéves szerző afféle emelt szintű lektűríró, abban az értelemben, kulturálisan értékes, mély, de egyben olvasmányos és szórakoztató regények kerülnek ki a kezei alól, lényegében évente egy. Hatalmas sikere van az egész világon.<br/>
                    Ez talán legérdekesebb könyve. Ez egy nagyon különleges történet, amely részben New Yorkban, részben Párizsban játszódik, helyenként egyszerre filozofikus és életszerű.<br/>
                    <i className={'text-brandDark lg:text-base'}>„Senki nem tud elpárologni csak úgy bújócskázás közben a lakásban. Ez az a játék, ami mindig jól végződik. Az eltűnést csak megjátsszák, rövid időre szól, szimbolikusan kell érteni, és a végén mindig megkerül, aki elrejtőzött. Másképp nem is lehet. Pont ez a lényeg: csak akkor bújócskázunk, ha tudjuk, hogy a másik elő fog kerülni a végén.”</i><br/>
                    Ezzel vigasztalja magát a regény első lapjain Flora Conway, az egyik legmenőbb és üzleti szempontból is legsikeresebb, ám Elena Ferrante (és Kondor Vilmos) módjára rejtőzködő szerző, akinek a hároméves kislánya rejtélyes körülmények között eltűnik a New York-i lakásukból, bújócskázás közben. Igazán eltűnik, a rendőrség hiába nyomoz, hiába nézik át a kamerafelvételeket.<br/>
                    Aztán Conway egyszer csak rájön, hogy egy másik szerző regényének szereplője lett. Ekkor a cselekmény hirtelen átvált Párizsba, egy Ozorski nevű író otthonába. És kezdetét veszi egy érzelmi hullámvasutazás, egy másfajta bújócska. A valóság és a fikció őrült módon pörög egymás körül, Musso mindvégig elhiteti velünk, hogy ez nem egy mese, hanem nagyon is megtörténhet. Közben Ozorski élete is rosszra fordul, volt felesége végleg el akarja venni tőle a fiát, azaz lassanként ugyanazt a gyötrelmet éli át, mint a hőse.<br/>
                    Ahogy lapozunk, egyre több bennünk a kétely. Ki volt előbb, Ozorski vagy Flora Conway? Hát Fantine, az irodalmi ügynök valóságos személy? Végül minden egyes kérdésre választ kapunk. És még az utolsó oldalakra is marad egy csavar. Izgalmas, játékos, ritka szórakoztató könyv.<br/>
                    (Guillaume Musso: Regényélet. Fordította Füsi Lídia. Park Könyvkiadó, Budapest, 2021, 224 oldal, 3999 Ft)
                </p>
                <h3>Bognár Péter: Hajózni kell, élni nem kell</h3>
                <img src="assets/19nap-konyv-5.jpg" alt="Könyv 5"
                     className={"block mb-2 mx-auto sm:float-left sm:mr-6 max-w-[150px]"}/>
                <p className={'text-brandDark lg:text-base'}>
                    <i className={'text-brandDark lg:text-base'}>„Az éjszaka fennmaradó részét a bázison töltöttem, elvégeztem a papírmunkát, és jegyzeteket készítettem, vagyis megpróbáltam rendszerezni a nap folyamán engem ért benyomásokat, és az azok alapján bennem kialakult elképzeléseket. (…) A polgárőrmunkának ezt a részét kifejezetten szeretem. Ilyenkor, ha becsukom a bejárati védmű ajtaját, teljes csönd van a bázison, ugyanis az irányításom alatt dolgozó állományt éjszakára vagy mozgó figyelő-jelző szolgálatra küldöm, vagy otthonában pihentetem, (…) így az érdemi munkát általában ezekre az órákra vagyok kénytelen halasztani. Ezek az órák azok, amelyek képesek az éppen folyamatban lévő ügyek véglegesnek hitt körvonalait megbontani, hogy a tények alkotta összefüggésrendszert átrendezve kijelöljék az olykor meglepő, máskor banális, de végül egyedül helyesnek bizonyuló irányt.”</i><br/>
                    Ebben a nagyon egyéni hangú, falusi környezetben játszódó, gyakran barokkosan cifra módon fogalmazó könyvben erlejétől a végéig egy falusi polgárőr parancsnok, Oktáv belső monológját halljuk. Ő már a történet elején előre-hátra utalgat egy gyilkosságra. Hamar kiderül, hogy ez nem csak az ő fantáziájában létezik. Azt is megtudjuk, hogy már tíz éve külön költözött feleségétől, két szenvedélye közül az egyikkel fel is hagyott: volt garázsában már kihasználatlanul hever az ipari teljesítményű, négymotoros aprítógép. Annál inkább hódol másik hobbijának: a falusiak okulására (Plutarkhosz nyomán) lépten-nyomon felidézi a görög és római politikusok viselt dolgait – és ezt az áldozatok meglepően jól tűrik. Oktáv monológjai zavarosnak tűnnek, de határozottan van bennük rendszer.<br/>
                    A polgárőrség telephelye (ahol Oktáv meghúzza magát) csak egy kis részét foglalja el a hajdani Mintagazdaságnak. A többi részen néhány éve egy helybéli, külföldön karriert csinált egykori fenegyerek „felnőtt segédeszközöket” előállító gyárat rendezett be. Ám ez a regény idejében már bezárt – ezt a plébános úr érte el, ideológiai alapon…<br/>
                    A főhős szavaiban van valami viccesen darabos és archaikus. Vannak bonyolult kifejezések, amelyeknek annyira megörül, hogy újra és újra hajszál pontosan megismétli őket. Afféle állandó jelzőként szolgáló bővített mondatok ezek, az író igen jó leleménye ez, amelyről stílusa messziről felismerhető.<br/>
                    Van még egy rejtélyes szereplője a könyvnek: egy Bognár nevű író, aki nem is nagyon tagadja, hogy a színielőadás alapját képező pamfletet ő írta, sőt át is adja a szöveget Oktávnak. Ő pedig szerencsére meg is osztja azt velünk. Témája a hős athéni hadvezér, Alkibiadész meghurcolása, némileg a mai Facebook lincselések modorában – igen szabados, mondhatni 21 éven felülieknek való nyelvezettel.<br/>
                    Óriási könyv ez, több, mint egy ígéretes prózaírói indulás. Legerősebb vonása a döbbenetesen sűrű atmoszféra, és a több tucat, remekül egyénített falusi figura óraműszerűen mozgatott panoptikuma.<br/>
                    (Magvető, 2022. 320 o., ára 3499 Ft.)
                </p>
                <h3>Cixin Liu: Hangyák és dinoszauruszok (Csontcitadella és Sziklaváros elveszett történelme)</h3>
                <img src="assets/19nap-konyv-6.jpg" alt="Könyv 6"
                     className={"block mb-2 mx-auto sm:float-right sm:ml-6 max-w-[150px]"}/>
                <p className={'text-brandDark lg:text-base'}>
                    Ezt a könyvet bízvást ajánlhatjuk minden olyan olvasónak, aki időnként hajlandó elhagyni a saját olvasási komfort-zónáját, akibe szorult valamennyi kalandozási hajlam.<br/>
                    A Prológusban a szerző azzal az ismert fogással kezdi, hogy ha a Föld történetét egyetlen napba sűrítenénk, akkor egy óra kétszázmillió évnek felelne meg. És ebből a napból az emberi civilizáció csak az utolsó tizedmásodpercekben jelenne meg.<br/>
                    A második bekezdés így hangzik: <i className={'text-brandDark lg:text-base'}>„De vajon hogyan alakult a földi élet az ezen tizedmásodperc előtti órákban? Vajon a többi élőlény mindössze csak úszott, futott, szaporodott és aludt? Évmilliárdokon át valóban ennyire tudatlanok lettek volna? Az élet fájának számtalan ágán egyedül a mi kis gallyacskánkra ragyogott rá az értelem fénye? Nem tűnik túl valószínűnek.”</i><br/>
                    Aztán arról beszél, hogy az értelem szikrája magától is kialudhat, mennyi minden kell ahhoz, hogy ebből civilizáció szökkenjen szárba. Ez a könyv egy olyan elképzelt, a késői krétakorba helyezett históriát mesél el (ekkor a földrészek még egészen másképp néztek ki), amikor nem is egy, hanem két, egymástól az égvilágon mindenben különböző, értelmes faj alakult ki, és éppen az együttműködésük hozta el a civilizációt. Ne tessék most kinevetni az ötletet – inkább olvassák el az első ívet, ami éppen 16 oldal.<br/>
                    Cixin Liu elképesztő valószerűséggel ábrázolja a két eltérő világ találkozását. A fejezetek ezer-ezer évet ugranak a történetben, az egyikben megjelenik az írás, a következőben a gőzgép, és eljutunk az információs korba is.<br/>
                    Persze lesz vallásháború, és a történetben hol az együttműködés, hol az ellenségeskedés kerül felülre. A dinoszaurusz-világ két részre bomlik, az ilyen fordulatok olvastán óhatatlanul Amerika felfedezése jut eszünkbe, máskor a harmincéves háború, vagy a zsidók és keresztények együttélése.<br/>
                    A könyv olvasható társadalmi-politikai parabolának is, de elősorban mégis felhőtlen szórakozást nyújt. Az író cselekményvezetése, jellemábrázolása, dialógusai időről időre elfelejtetik az olvasóval, hogy nem valós történetet olvas.<br/>
                    Nem, a könyv nem ifjúsági regény (ahogy manapaság mondják, „young adult” irodalom). Mára a sci-fi végképp a felnőttek műfaja (is) lett. Cixin Liu olvasása – nem kizárt – kapudrog lehet egyes olvasóknak, azaz újabb hívei lesznek a műfajnak.<br/>
                    (Európa Kiadó, 2021, Kapszula könyvek. Fordította Dranka Anita. A borítót tervezte: Tabák Miklós. 204 o., ára 1999 Ft.)
                </p>
            </>
    },
    {
        id: 20, content:
            <>
                <h2>Tekintsd meg ezt a kedves karácsonyi videót!</h2>
                <p className={'text-brandDark lg:text-base'}>„Minden, ami igazán fontos, csak idővel és figyelemmel
                    épülhet fel.” – Müller Péter</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/sOyJ3FExfSE"
                            title="Christmas Animation - The Snowman" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/sOyJ3FExfSE"
                            title="Christmas Animation - The Snowman" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                </div>
            </>
    },
    {
        id: 21, content:
            <>
                <h2>Rögtön puha mézes puszedli</h2>
                <p className={'text-brandDark lg:text-base'}>Illatos, fűszeres, mézes és pihe-puha ez a puszedli, a
                    karácsonyi asztalon kötelező helye van, ráadásul nem csak finom, mutatós is.</p>
                <h3>Recept</h3>
                <img src="assets/21nap-puszedli.jpg" alt="Puszedli"/>
                <h4 className={"text-brandDark my-4"}>Hozzávalók - 35 darabhoz</h4>
                <p><strong className={"text-brandDark"}>Puszedli:</strong></p>
                <div className={"block md:flex"}>
                    <p className={"text-brandDark w-80 mb-0 md:mb-4"}>
                        &bull; 50 dkg liszt<br/>
                        &bull; 1 tk. szódabikarbóna<br/>
                        &bull; 1 tk. fahéj<br/>
                        &bull; fél tk. szegfűszeg<br/>
                        &bull; negyed tk. gyömbér<br/>
                        &bull; negyed tk. szerecsendió<br/>
                    </p>
                    <p className={"text-brandDark"}>
                        &bull; 15 dkg méz<br/>
                        &bull; 15 dkg porcukor<br/>
                        &bull; 7,5 dkg vaj<br/>
                        &bull; 1 dl tej<br/>
                        &bull; 3 db tojássárgája
                    </p>
                </div>
                <p><strong className={"text-brandDark"}>Máz:</strong></p>
                <div className={"block md:flex"}>
                    <p className={"text-brandDark w-80 mb-0 md:mb-4"}>
                        &bull; 3 db tojásfehérje<br/>
                    </p>
                    <p className={"text-brandDark"}>
                        &bull; 15 dkg porcukor<br/>
                    </p>
                </div>
                <p className={"text-brandDark"}>Elkészítési idő: 25 perc | Előkészítési idő: 20 perc | Pihentetési idő:
                    2
                    óra</p>
                <h4 className={"text-brandDark mb-4"}>Elkészítés:</h4>
                <p className={"text-brandDark"}>
                    A lisztet és a szódabikarbónát szitáljuk tálba, keverjük el benne a fűszereket.<br/>
                    A mézet tegyük egy kis tálkába, majd a mikróban olvasszuk fel 30-45 másodperc alatt. Utána keverjük
                    habosra a cukorral és a vajjal, majd öntsük fel a tejjel. Keverjük bele a tojássárgákat is.<br/>
                    Forgassuk bele a lisztes keveréket, röbb részletben adagoljuk, míg össze nem áll a tészta, ekkor még
                    nagyon ragacsos.<br/>
                    Tegyük be a hűtőbe 1-2 órára pihenni, hogy könnyebben formázható legyen.<br/>
                    Közben melegítsük elő a sütőt 180 fokra. Béleljünk ki egy tepsit sütőpapírral.<br/>
                    Formázzunk a tésztából diónyi golyókat, majd tegyük őket a tepsibe. Toljuk a sütőbe 15-20 percre,
                    míg az aljuk aranybarna lesz.<br/>
                    Közben a tojásfehérjét kezdjük el verni vízgőz felett. Amikor habos a teteje, adjuk hozzá a
                    porcukrot. Nem kell belőle teljesen kemény habot verni, csak addig keverjük habverővel, míg sűrű,
                    kenhető massza nem lesz belőle. Kenjük le vele a puszedliket. Tegyük őket vissza a lekapcsolt sütőbe
                    száradni, vagy szobahőmérsékleten hagyjuk teljesen megszáradni, mielőtt kínálnánk.<br/>
                </p>
                <p className={'text-brandDark lg:text-sm lg:mt-8'}><strong className={"text-brandDark"}>Forrás: </strong><br/>
                    <a
                        href={"https://sobors.hu/receptek/mezes-puszedli-recept/"}
                        target={"blank"}>https://sobors.hu/receptek/mezes-puszedli-recept/</a>
                </p>
            </>
    },
    {
        id: 22, content:
            <>
                <h2>Mozipremierek decemberben</h2>
                <p className={'text-brandDark lg:text-base'}>
                    Decemberben se felejtsetek el minőségi időt tölteni a szeretteitekkel a nagy rohanás közepette. Kiváló alkalom lehet a közös programra a decemberben esedékes mozipremierek. Ebből ajánlunk nektek kettőt, melyek előzeteseit itt tekinthetitek meg:
                </p>
                <div className={"block md:flex justify-center gap-16"}>
                    <div>
                        <p className={"text-base text-brandTertiary font-bold text-center"}>KICSIKNEK</p>
                        <h4 className={"my-4 text-center"}>Mufasa: Az oroszlánkirály</h4>
                        <div className={'md:flex justify-center my-4 hidden'}>
                            <iframe width="320" height="170" src="https://www.youtube.com/embed/gEG_UC1_2BQ"
                                    title="Mufasa: Az oroszlánkirály - magyar szinkronos előzetes #2" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                        </div>
                        <div className={'flex justify-center mb-6 md:my-4 md:hidden'}>
                            <iframe width="100%" height="140" src="https://www.youtube.com/embed/gEG_UC1_2BQ"
                                    title="Mufasa: Az oroszlánkirály - magyar szinkronos előzetes #2" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                        </div>
                    </div>
                    <div>
                        <p className={"text-base text-brandTertiary font-bold text-center"}>NAGYOKNAK</p>
                        <h4 className={"my-4 text-center"}>Hogyan tudnék élni nélküled?</h4>
                        <div className={'md:flex justify-center my-4 hidden'}>
                            <iframe width="320" height="170" src="https://www.youtube.com/embed/dolW5_ieIE8"
                                    title="Hogyan tudnék élni nélküled? - Teaser előzetes (12E)" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                        </div>
                        <div className={'flex justify-center my-4 md:hidden'}>
                            <iframe width="100%" height="140" src="https://www.youtube.com/embed/dolW5_ieIE8"
                                    title="Hogyan tudnék élni nélküled? - Teaser előzetes (12E)" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                        </div>
                    </div>
                </div>
            </>
    },
    {
        id: 23, content:
            <>
                <h2>Hangolódj az ünnepekre!</h2>
                <p className={'text-brandDark lg:text-base'}>
                    Fogadd szeretettel ezt a dalt, és éld át az Ünnepek hangulatát.
                </p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/E8gmARGvPlI"
                            title="Wham! - Last Christmas (Official Video)" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/E8gmARGvPlI"
                            title="Wham! - Last Christmas (Official Video)" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                </div>
            </>
    },
    {
        id: 24, content:
            <>
                <img src="assets/24nap-karacsonyi-kepeslap.jpg" alt="ALFA Karácsonyi képeslap"/>
            </>
    },
]
